/* Global CSS goes here */
:root {
  --monsta-yellow: #fae750;
  --monsta-gray: #6c6c6c;
  --white: #ffffff;
  --card-light-green: #2e3a36;
  --card-dark-green: #1b2924;
  --monsta-gray-2: #989898;
}

@font-face {
  font-family: "Molot";
  src: local("Molot"), url("./assets/fonts/Molot/Molot.otf") format("opentype");
}

@font-face {
  font-family: "Obelix Pro";
  src: local("Obelix Pro"),
    url("./assets/fonts/ObelixPro/ObelixPro.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  src: local("Work Sans"),
    url("./assets/fonts/Work_Sans/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Bowlby One";
  src: local("Bowlby One"),
    url("./assets/fonts/Bowlby_One/BowlbyOne-Regular.ttf") format("truetype");
}
